<template lang="pug">
.app
  EcreativAtomsSpinner(v-if="isPageLoading", isClear, isFullPage)
  nuxtErrorBoundary
    template(#error='{ error }')
      p An error occurred: {{ error }}
    slot
  EcreativOrganismsArgumentsModal
  EcreativMoleculesGoTop
</template>

<script lang="ts" setup>
import { onMounted, defineProps, provide, computed } from 'vue';
import { useFetch } from '#imports';

const articlesStore = useArticlesStore();

defineProps({
  isPageLoading: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  if (window.location.href.includes('#about')) {
    const aboutElement = document.querySelector('#about') as HTMLElement;
    if (aboutElement) {
      window.scrollTo({
        top: aboutElement.offsetTop,
        behavior: 'smooth',
      });
    }
  }
});

const { data: aboutSchema } = await useFetch('/api/about');
const { data: messiahInfo } = await useFetch('/api/messiah');
const { data: sidebarSchema } = await useFetch('/api/sidebar');
const { data: introData } = await useFetch('/api/intro');

const { data: latestArticles } = await useFetch(
  '/api/fetchArticles?categoryKey=articles&per_page=6',
);

const { data: elevenPoints } = await useFetch(
  '/api/fetchArticles?categoryKey=elevenPoints&per_page=11',
);

const { data: secondNavLinks } = await useFetch(
  '/api/shell/navigation/footer?name=secondNavLinks',
);

const { data: socialLinks } = await useFetch(
  '/api/shell/navigation/footer?name=socialLinks',
);

const { data: questionsAndAnswersLinksHeader } = await useFetch(
  '/api/shell/navigation/footer?name=questionsAndAnswersLinksHeader',
);

const { data: beliefsLinksHeader } = await useFetch(
  '/api/shell/navigation/footer?name=beliefsLinksHeader',
);

const { data: campaignsLinksHeader } = await useFetch(
  '/api/shell/navigation/footer?name=campaignsLinksHeader',
);

const { data: discoverLinksHeaderMobile } = await useFetch(
  '/api/shell/navigation/footer?name=discoverLinksHeaderMobile',
);

const { data: questionsAndAnswersLinksHeaderMobile } = await useFetch(
  '/api/shell/navigation/footer?name=questionsAndAnswersLinksHeaderMobile',
);

const { data: moreLinksHeaderMobile } = await useFetch(
  '/api/shell/navigation/footer?name=moreLinksHeaderMobile',
);

const { data: beliefsLinks } = await useFetch(
  '/api/shell/navigation/footer?name=beliefsLinks',
);

const { data: questionsAndAnswersLinks } = await useFetch(
  '/api/shell/navigation/footer?name=questionsAndAnswersLinks',
);

const { data: legalLinks } = await useFetch(
  '/api/shell/navigation/footer?name=legalLinks',
);

// Provide the fetched data
provide('mainNavLinks', [
  ...beliefsLinksHeader.value,
  ...questionsAndAnswersLinksHeader.value,
  ...campaignsLinksHeader.value,
]);

provide('mainNavLinksMobile', [
  ...discoverLinksHeaderMobile.value,
  ...moreLinksHeaderMobile.value,
  ...questionsAndAnswersLinksHeaderMobile.value,
]);

provide('secondNavLinks', secondNavLinks.value);

provide('footerLinks', [
  ...beliefsLinks.value,
  ...questionsAndAnswersLinks.value,
  ...socialLinks.value,
]);

provide('legalLinks', legalLinks.value);

provide('aboutSchema', aboutSchema.value);
provide('messiahInfo', messiahInfo.value);
provide('sidebarSchema', sidebarSchema.value);
provide('introData', introData.value);

provide('latestArticles', latestArticles.value.data);

const { data: articlesData } = await useFetch(
  '/api/fetchArticles?categoryKey=articles&per_page=6',
);

articlesStore.articles = articlesData.value.data;
articlesStore.totalPages = articlesData.value.totalPages;

provide(
  'articlesData',
  computed(() => articlesStore.getArticles),
);

provide('elevenPoints', elevenPoints.value.data);
</script>
